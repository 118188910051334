<!-- 考生统计表 -->
<template>
  <div class="examineeCensus">
    <!-- 查询表单 -->
    <el-form
      ref="form"
      :model="form"
      inline
      class="form"
      id="form"
      label-width="120px"
    >
      <el-form-item label="考试日期">
        <el-date-picker
          v-model="form.dateQuery"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="机构名称">
        <el-select
          v-model="form.mechanism"
          filterable
          placeholder="请选择机构"
          :clearable="true"
        >
          <el-option
            v-for="item in form.mechanismOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择地区">
        <el-select
          v-model="form.region"
          placeholder="请选择地区"
          :clearable="true"
        >
          <el-option
            v-for="item in form.regionOpt"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试大类">
        <el-select
          v-model="form.exam"
          filterable
          placeholder="请选择考试大类"
          :clearable="true"
        >
          <el-option
            v-for="item in form.examOpt"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="搜索">
        <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
      </el-form-item>
      <el-form-item label="单据状态">
        <el-select
          v-model="form.condition"
          placeholder="请选择单据状态"
          :clearable="true"
        >
          <el-option
            v-for="item in form.conditionChoose"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考场安排">
        <el-select
          v-model="form.arrange"
          placeholder="请选择"
          :clearable="true"
        >
          <el-option
            v-for="item in form.arrangeOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考场">
        <div style="width: 276px">
          <el-radio-group v-model="form.room">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">单科考场</el-radio>
            <el-radio :label="2">综合考场</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
    </el-form>
    <div class="" style="display: flex; justify-content: space-between">
      <div
        class=""
        style="margin-left: 22px; display: flex; align-items: center"
      >
        <el-button
          size="small"
          icon="el-icon-refresh"
          style="background-color: #2c3e50; border-radius: 3px"
          @click="refresh"
        >
          <!-- 刷新 -->
        </el-button>

        <button class="print" @click="print">打印预览</button>
        <button class="derive" @click="derive">导出到Excel</button>
      </div>
      <div class="" style="margin-right: 10px; text-align: right">
        <el-button
          size="small"
          style="background: #18bc9c; color: #ffffff"
          @click="query"
        >
          查询
        </el-button>
        <el-button
          size="small"
          style="background: #fafafa; color: #666666"
          @click="Reset"
        >
          重置
        </el-button>
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 97.9%; flex: 1"
      height="650"
      @sort-change="abc"
      @selection-change="checkAll"
    >
      <el-table-column type="selection" align="center" width="50">
      </el-table-column>
      <el-table-column prop="code" label="编码" align="center" width="150">
      </el-table-column>
      <el-table-column
        prop="org_name"
        sortable="custom"
        label="机构名称"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        sortable="custom"
        label="机构电话"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="principal"
        sortable="custom"
        label="机构负责人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        sortable="custom"
        label="考试场地"
        align="center"
        width="300"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="area"
        sortable="custom"
        label="所属区域"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="student_count"
        sortable="custom"
        label="学员人数"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="exam_count"
        sortable="custom"
        label="应考人次"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="defect_num"
        sortable="custom"
        label="缺考人次"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="certificatecopy_price"
        sortable="custom"
        label="副证费(元)"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="enroll_price"
        sortable="custom"
        label="考试报名费(元)"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="price1"
        sortable="custom"
        label="应收金额(元)"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="ratio"
        sortable="custom"
        label="所占比例(%)"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="price2"
        sortable="custom"
        label="实际金额(元)"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="difference"
        sortable="custom"
        label="未收金额(元)"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column label="明细表" align="center" width="130" fixed="right">
        <template slot-scope="scope">
          <el-button
            class="more"
            icon="el-icon-view"
            size="mini"
            @click="more(scope.$index, scope.row)"
          >
            查看更多
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 底部 -->
    <div class="" style="display: flex; justify-content: space-between">
      <div class="" style="margin-left: 22px">
        <div class="txtbox">
          学员数量：<input
            type="text"
            class="itp"
            :value="studentNum"
            disabled="disabled"
          />
        </div>
        <div class="txtbox">
          应考人次：<input
            type="text"
            class="itp"
            :value="personTime"
            disabled="disabled"
          />
        </div>
        <div class="txtbox">
          应收金额汇总：<input
            type="text"
            class="itp"
            :value="sum"
            disabled="disabled"
          />
        </div>
        <div class="txtbox">
          记录数：<input
            type="text"
            class="itp"
            :value="count"
            disabled="disabled"
          />
        </div>
        <div class="txtbox">
          副证费金额：<input
            type="text"
            class="itp"
            :value="certificateFee"
            disabled="disabled"
          />
        </div>
        <div class="txtbox">
          报名费金额：<input
            type="text"
            class="itp"
            :value="registrationFee"
            disabled="disabled"
          />
        </div>
        <div class="txtbox">
          实收金额：<input
            type="text"
            class="itp"
            :value="netReceipts"
            disabled="disabled"
          />
        </div>
        <div class="txtbox">
          未收金额：<input
            type="text"
            class="itp"
            :value="receivable"
            disabled="disabled"
          />
        </div>
      </div>
      <div class="" style="margin-right: 22px">
        <!--分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100, 200, 500, 1000]"
          :page-size="10"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 打印预览弹窗 -->
    <el-dialog
      width="900px"
      :visible.sync="dialog"
      id="dialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div id="printRollcall">
        <!-- 点名表内容 -->
        <div class="rollcall">
          <!-- 头部 -->
          <h2>{{ rollTitle }}机构报考清单</h2>
          <!-- <div class="top">
            <div class="top_1">考场名称 : {{ item.site_name }}</div>
            <div class="top_2">
              监考老师 : <input :value="item.teacher" style="font-size: 16px" />
            </div>
          </div> -->
          <!-- 中间 -->
          <!-- <div class="center">
            <div class="c_1">
              考试时间 : {{ item.exam2_date }} ( {{ item.exam_startdate }} --
              {{ item.exam_enddate }} )
            </div>
            <div class="c_2">
              共 {{ item.sum_page }} 页-第 {{ index + 1 }} 页
            </div>
          </div> -->
          <!-- 表格 -->
          <el-table
            :data="rollcall"
            border
            :header-cell-style="{ background: '#ffff7f', color: '#333' }"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="40"
            >
            </el-table-column>
            <el-table-column
              prop="idcard"
              label="身份证号"
              align="center"
              width="160"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center" width="85">
            </el-table-column>
            <el-table-column
              prop="english_name"
              label="拼音名"
              align="center"
              width="150"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="gender"
              label="性别"
              align="center"
              width="43"
            >
            </el-table-column>
            <el-table-column
              prop="nation"
              label="民族"
              align="center"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="birthdate"
              label="出生日期"
              align="center"
              width="90"
            >
            </el-table-column> -->

            <el-table-column
              prop="course"
              label="报考专业"
              align="center"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="exam_level"
              label="报考级别"
              align="center"
              width="60"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="exam_type"
              label="考试类型"
              align="center"
              width="100"
              :show-overflow-tooltip="true"
            >
            </el-table-column>   -->
            <el-table-column
              label="考试状态"
              align="center"
              width="60"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{ scope.row.status | examinationStatus }}
              </template>
            </el-table-column>

            <el-table-column
              prop="org_name"
              label="机构名称"
              align="center"
              width="140"
            >
            </el-table-column>
            <el-table-column
              prop="PRemark"
              label="备注"
              align="center"
              width="83"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="record">
          <div class="">
            <span>考生数量：</span><input type="" :value="sums" disabled />
          </div>
          <div class="">
            <span>实考人数：</span><input type="" value="" disabled />
          </div>
          <div class="">
            <span>缺考人数：</span><input type="" value="" disabled />
          </div>
          <div class="">
            <span>监考签名：</span><input type="" value="" disabled />
          </div>
        </div>
        <p style="color: #333; padding: 15px 0px; font-size: 15px">
          <span style="font-weight: bold"> 备注说明 : </span>
          <input
            type="text"
            value=""
            style="
              width: 91%;
              border: none;
              border-bottom: 1px solid #333;
              outline: none;
              background: #fff;
            "
            disabled
          />
        </p>
      </div>
      <!-- 底部打印按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button
          style="background: #18bc9c; color: #fff"
          v-print="'#printRollcall'"
        >
          打 印
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startdate: "", //开始日期
      enddate: "", //结束日期
      form: {
        dateQuery: [], //考试日期
        mechanism: "", //机构名称
        mechanismOpt: [], //机构名称选项
        exam: "", //考试大类
        examOpt: [], //考试大类选项
        region: "", //选择地区
        regionOpt: [
          {
            label: "广东省",
            value: 0,
          },
          {
            label: "龙岗区",
            value: 1,
          },
          {
            label: "龙华区",
            value: 2,
          },
          {
            label: "福田区",
            value: 3,
          },
          {
            label: "罗湖区",
            value: 4,
          },
          {
            label: "南山区",
            value: 5,
          },
          {
            label: "宝安区",
            value: 6,
          },
          {
            label: "光明区",
            value: 7,
          },
          {
            label: "盐田区",
            value: 8,
          },
          {
            label: "坪山区",
            value: 9,
          },
          {
            label: "大鹏新区",
            value: 10,
          },
          {
            label: "深汕合作区",
            value: 11,
          },
          {
            label: "港澳台地区",
            value: 12,
          },
          {
            label: "其他",
            value: 13,
          },
        ], //地区选项
        search: "", //搜索
        condition: 0, //单据状态
        conditionChoose: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "草稿",
          },
          {
            value: 2,
            label: "报考",
          },
          {
            value: 3,
            label: "准考",
          },
          {
            value: 4,
            label: "考试OK",
          },
          {
            value: 5,
            label: "取消",
          },
          {
            value: 6,
            label: "退款取消",
          },
          {
            value: 7,
            label: "延期",
          },
          {
            value: 8,
            label: "缺考",
          },
        ], //单据状态选择
        arrangeOpt: [
          { value: 0, label: "全部" },
          { value: 1, label: "未安排" },
          { value: 2, label: "已安排" },
        ],
        arrange: 0,
        room: 0, //考场
      },
      currentPage: 1,
      currentLimit: 20, //条数
      total: 0,
      tableData: [],
      studentNum: "", //学员数量
      personTime: "", //应考人次
      sum: "", //应收金额
      count: "", //记录数
      certificateFee: "", //副证费金额
      registrationFee: "", //报名费金额
      netReceipts: "", //实收金额
      receivable: "", //未收金额
      arr: [],
      id: "",
      dialog: false,
      rollcall: [],
      rollTitle: "",
      sums: 0,
    };
  },
  created() {
    this.getTime();
    this.getList();

    // 选择机构
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("选择机构",res.data.list)
      this.form.mechanismOpt = res.data.list;
    });

    //考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("考试大类",res.data.list)
      this.form.examOpt = res.data.list;
    });
  },
  activated() {
    this.getList();
  },
  methods: {
    getTime() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1; //0-11表示1-12月
      month = month.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
      let day = now.getDate();
      day = day.toString().padStart(2, "0"); //当小于10时补0
      this.enddate = year + "-" + month + "-" + day;
      console.log(this.enddate, "当前时间");
      let nowMonthDay = new Date(year, month, 0).getDate(); //当前月的总天数
      if (month - 3 <= 0) {
        //如果是1、2、3月，年数往前推一年
        let last3MonthDay = new Date(
          year - 1,
          12 - (3 - parseInt(month)),
          0
        ).getDate(); //3个月前所在月的总天数
        if (last3MonthDay < day) {
          //3个月前所在月的总天数小于现在的天日期
          this.enddate =
            year -
            1 +
            "-" +
            (12 - (3 - month)).toString().padStart(2, "0") +
            "-" +
            last3MonthDay;
        } else {
          this.startdate =
            year -
            1 +
            "-" +
            (12 - (3 - month)).toString().padStart(2, "0") +
            "-" +
            day;
        }
      } else {
        let last3MonthDay = new Date(year, parseInt(month) - 3, 0).getDate(); //3个月前所在月的总天数
        if (last3MonthDay < day) {
          //3个月前所在月的总天数小于现在的天日期
          if (day < nowMonthDay) {
            //当前天日期小于当前月总天数,2月份比较特殊的月份
            this.enddate =
              year +
              "-" +
              (month - 3).toString().padStart(2, "0") +
              "-" +
              (last3MonthDay - (nowMonthDay - day));
          } else {
            this.startdate =
              year +
              "-" +
              (month - 3).toString().padStart(2, "0") +
              "-" +
              last3MonthDay;
          }
        } else {
          this.startdate =
            year + "-" + (month - 3).toString().padStart(2, "0") + "-" + day;
        }
      }
      this.form.dateQuery[0] = this.startdate;
      this.form.dateQuery[1] = this.enddate;
      console.log(this.form.dateQuery, "三个月区间段");
    },
    getList() {
      this.$request({
        url: "/api/Statistics/list",
        method: "POST",
        data: {
          page: this.currentPage,
          limit: this.currentLimit,
          dateQuery: this.form.dateQuery,
          org_id: this.form.mechanism,
          sort: this.arr,
          apply_category: this.form.exam, //考试大类id
          area: this.form.region,
          status: this.form.condition,
          search: this.form.search,
          exam_arrange: this.form.arrange,
          exam_type: this.form.room, //考场
        },
      }).then((res) => {
        console.log(res, "列表数据");
        this.tableData = res.data.list;
        this.total = res.data.count;
        this.studentNum = res.data.student_count; //学员数量
        this.personTime = res.data.exam_count; //应考人次
        this.sum = res.data.price1; //应收金额
        this.count = res.data.count; //记录数
        this.certificateFee = res.data.certificatecopy_price; //副证费金额
        this.registrationFee = res.data.enroll_price; //报名费金额
        this.netReceipts = res.data.price2; //实收金额
        this.receivable = res.data.difference; //未收金额
      });
    },
    handleSizeChange(val) {
      this.currentLimit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    // 刷新
    refresh() {
      this.$router.push({
        path: "/home/newpage", //空页面路由
        query: {
          path: this.$route.path,
        },
      });
    },
    more(idnex, row) {
      //查看更多
      this.$router.push({
        path: "examineeDetails",
        query: {
          org_id: row.org_id,
          dateQuery: this.form.dateQuery,
        },
      });
    },
    query() {
      //查询
      this.currentPage = 1;
      this.getList();
    },
    Reset() {
      //重置
      this.form.dateQuery = []; //考试日期
      this.form.mechanism = ""; //机构名称
      this.form.region = ""; //选择地区
      this.form.search = "";
      this.form.exam = ""; //考试大类
      this.arr = [];
      this.exam = "";
      this.form.condition = 0;
      this.form.arrange = 0;
      this.form.room = 0; //考场
      this.getList();
    },
    print() {
      //打印预览
      this.dialog = true;
      this.$request({
        url: "/api/Statistics/student_callroll",
        method: "POST",
        data: {
          apply_category: this.form.exam, //考试大类id
          org_id: this.id,
          dateQuery: this.form.dateQuery,
          status: this.form.condition,
        },
      }).then((res) => {
        console.log(res, "预览数据");
        if (res.code == 1) {
          this.rollcall = res.data.list;
          this.rollTitle = res.data.apply_category_name;
          this.sums = res.data.list.length;
        } else {
          this.dialog = false;
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    derive() {
      //导出
      location.href = `https://artxt.szart.cn/api/public/index.php/api/Statistics/export?
                    page=${this.currentPage}&limit=${this.currentLimit}&dateQuery=${this.form.dateQuery}
                    &org_id=${this.form.mechanism}&status=${this.form.condition}&apply_category=${this.form.exam}&exam_arrange=${this.form.arrange}&exam_type=${this.form.room}`;
    },

    // 姓名排序
    fn2(a, b) {
      return a.principal.localeCompare(b.principal);
    },
    //排序
    abc(i) {
      console.log(i);
      this.tableData = [];
      this.arr = [];
      this.arr.push({
        title: i.prop,
        order: i.order,
      });
      this.getList();
    },
    checkAll(selection) {
      //获取批量id
      //  console.log(selection)
      let id = "";
      selection.forEach((item) => {
        // id.push(item.org_id)
        id = item.org_id;
      });
      // this.id  = id.join(',') // 取选中的全部id
      this.id = id; // 取选中的全部id
      // this.id = id[id.length-1] //取最后一个id
      // console.log( this.id,"id")
    },
  },
  filters: {
    examinationStatus(val) {
      const array = [
        "",
        "草稿",
        "报考",
        "准考",
        "考试ok",
        "取消",
        "退款取消",
        "延期",
        "缺考",
      ];
      return array[val];
    },
  },
};
</script>

<style scoped="scoped">
.examineeCensus {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 查询部分 */
.form {
  padding-top: 34px;
}

.form ::v-deep .el-input__inner {
  width: 276px;
  height: 37px;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}

.form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* 按钮 */
::v-deep .el-icon-refresh {
  font-weight: bold !important;
  color: #ffffff;
}

/* 导出按钮 */
.derive {
  display: inline-block;
  width: 113px;
  height: 30px;
  padding-left: 10px;
  vertical-align: middle;
  background: url(../../assets/export.png) 6px 6px no-repeat #ff7300;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}
/* 表格 */
.el-table {
  margin: 20px;
}

::v-deep .el-table__cell {
  color: #000;
  padding: 0;
  height: 30px;
}

.more {
  background: #18bc9c;
  color: #fff;
}

/* 显示值 */
.txtbox {
  height: 19px;
  width: 260px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
  font-size: 14px;
}

.itp {
  width: 150px;
  height: 20px;
  padding-left: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
  background-color: #ffffff;
}

/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}
/* 打印预览 */
.print {
  display: inline-block;
  padding-left: 10px;
  width: 94px;
  height: 30px;
  border-radius: 3px;
  background: url(../../assets/print.png) 8px 9px no-repeat #18bc9c;
  border: none;
  color: #ffffff;
  margin-left: 10px;
  cursor: pointer;
}
::v-deep .el-dialog__body {
  padding: 25px 15px;
}
::v-deep .el-dialog__footer {
  padding: 10px 20px 20px !important;
  text-align: center;
}

#printRollcall {
  width: 850px;
  margin: auto;
}

.rollcall {
  width: 100%;
  color: #000;
}

/* 标题 */
.rollcall h2 {
  text-align: center;
  color: #333;
}

/* 头部内容 */
.rollcall .top {
  padding: 0px 10px;
  margin-top: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.rollcall .top .top_2 input {
  width: 100px;
  outline: none;
  border: none;
}

.rollcall .center {
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.rollcall .center .c_2 {
  color: blue;
  padding-right: 20px;
}
/* 表格 */
.rollcall .el-table {
  width: 100% !important;
  margin: 0px auto;
  margin-top: 10px;
  padding: 0px;
}

.rollcall ::v-deep .el-table__cell {
  height: 35px;
  padding: 0px !important;
}

.rollcall ::v-deep .el-table th.el-table__cell > .cell {
  padding: 0px !important;
}

.rollcall ::v-deep .el-table .cell {
  padding: 0px !important;
}

.rollcall ::v-deep .el-table--border,
.el-table--group {
  border: 1px solid #000000;
  border-color: #000;
}

/* 数据 */
.record {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.record span {
  font-weight: bold;
  color: #333;
}

.record input {
  outline: none;
  border: none;
  border-bottom: 1px solid #333;
  background: #fff;
  width: 100px;
  color: #333;
  padding-left: 5px;
}
</style>
